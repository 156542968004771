.ProjectCard {
    margin-bottom: 31px;
}

.Image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 1px 10px #999;
}

.Info {
    background-color: rgba(27, 43, 79, 0.95);
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    height: calc(100% - 31px);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.Info:hover {
    opacity: 1;
    cursor: pointer;
}

.Description {
    color: white;
    margin: auto;
}

.Title {
    font-weight: 700;
    font-size: 1.8rem;
}

.Subtitle {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
    font-size: 1rem;
}

.Icon {
    color: white;
    cursor: pointer;
    font-size: 2.3rem;
    padding-top: 10px;
    transition: 0.1s ease-in-out;
}

.Icon:hover {
    color: #FF5CFF;
    transition: 0.1s ease-in-out;
}

@media (min-width: 768px) {
    .Title {
        font-size: 3em;
    }
    .Subtitle {
        font-size: 1.5rem;
    }
    .Icon {
        font-size: 3rem;
        padding-top: 15px;
    }
}

@media (min-width: 992px) {
    .Title {
        font-size: 1.2rem;
    }
    .Subtitle {
        font-size: 0.7rem;
    }
    .Icon {
        font-size: 1.5rem;
        padding-top: 5px;
    }
}

@media (min-width: 1200px) {
    .Title {
        font-size: 1.8rem;
    }
    .Subtitle {
        font-size: 1rem;
    }
    .Icon {
        font-size: 2.3rem;
        padding-top: 10px;
    }
}