.Profile {
    /*text-align: center;*/
    margin: 0 20px;
}

.Profile img {
    width: 80%;
    border-radius: 50%;
    margin-bottom: 30px;
    box-shadow: -3px 3px 10px #777;
}

.Title {
    font-family: 'Source Code Pro', monospace;
    font-size: 2.2rem;
    margin-bottom: 10px;
}

.Letter {
    transition: 2s;
}

.Letter:hover {
    color: #dc389c;
    transition: 0.05s;
    cursor: default;
}