.FilterButton {
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Source Code Pro', monospace;
    font-size: 1.5rem;
    padding: 10px;
    border-radius: 10px;
}

.FilterButton:hover {
    background-color: #FF5CFF;
    color: white;
    transition: 0.2s;
}

.Default {
    color: black;
    transition: 0.2s;
}

.Selected {
    background-color: #FF5CFF;
    color: white;
    transition: 0.2s;
}