.Headline {
    height: calc(100vh);
}

.Fill {
    background-color: #1b2b4f;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}

.Title {
    font-size: 5.5vw;
    font-weight: bold;
}

.Name {
    color: #FF5CFF;
}

.Subtitle {
    font-size: 4.5vw;
    font-family: 'Source Code Pro', monospace;
}

.Arrow {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%,-15%);
}

.Arrow span{
    display: block;
    width: calc(1vw + 1vh);
    height: calc(1vw + 1vh);
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: arrowAnimation 2s infinite;
}

.Arrow span:nth-child(2){
    animation-delay: -0.2s;
}

.Arrow span:nth-child(3){
    animation-delay: -0.4s;
}

@keyframes arrowAnimation {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}

@media (min-width: 768px) {
    .Title {
        font-size: 4vw;
    }

    .Subtitle {
        font-size: 3vw;
    }
}