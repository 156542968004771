.Skills {
    margin: 0 20px;
}

.SkillSection {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
}

.SkillTitle {
    font-size: 2rem;
    margin-bottom: 20px;
    font-family: 'Source Code Pro', monospace;
}

.Item {
    background-color: #1b2b4f;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    padding: 8px 30px 10px;
    display: inline-block;
    font-size: 1.2rem;
}

.Icon {
    font-size: 2em;
}
