.Portfolio {
    background-color:#fff;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.Projects {
    padding-top: 20px;
    margin-top: 20px;
}

.NoScroll {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

/* Small hack to prevent flickering when selecting filter buttons and div height gets very small for a moment */
@media (min-width: 576px) {
    .Portfolio {
        min-height: 1000px;
    }
}

@media (min-width: 768px) {
    .Portfolio {
        min-height: 1000px;
    }
}

@media (min-width: 992px) {
    .Portfolio {
        min-height: 755px;
    }
}

@media (min-width: 1200px) {
    .Portfolio {
        min-height: 845px;
    }
}