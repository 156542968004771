.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
}

.Content {
    color: #dcdcdc;
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 100px;
    padding-bottom: 100px;
    overflow-y: auto;
}

.CloseButton {
    color: #ddd;
    font-size: 1.25em;
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px;
}

.CloseButton:hover {
    cursor: pointer;
}

.Info {
    text-align: left;
}

.Title {
    font-weight: 700;
    font-size: 2em;
    line-height: 1.2em;
}

.Time {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.9em;
    letter-spacing: 2px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.Tags {
    margin-bottom: 30px;
}

.Tag {
    background-color: #2a3e6d;
    color: white;
    display: inline-block;
    margin-right: 10px;
    padding: 4px 10px 4px;
    border-radius: 50px;
    font-size: 0.9em;
    font-weight: 300px;
}

.Description {
    margin-bottom: 40px;
}

.LinkButton {
    background-color: lightgray;
    color: black;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.LinkButton:hover {
    background-color: white;
    color: black;
    text-decoration: none;
}

@media (max-width: 1199.98px) {
    .Info {
        text-align: center;
    }
    .Description {
        text-align: left;
    }
}
