.Carousel {
    width: 100%;
    margin-bottom: 40px;
}

.View img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.VideoContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 20px;
}

.Video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.Caption {
    font-size: 0.9rem;
    margin-bottom: 8px;
}

.Pagination img {
    width: 25%;
    border-radius: 8px;
    padding: 5px;
    opacity: 0.5;
}

.Pagination img:hover {
    cursor: pointer;
}

#SelectedSlide {
    opacity: 1;
}

@media (min-width: 768px) {
    .Pagination img {
        border-radius: 15px;
        padding: 10px;
    }
}