@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App_App__16ZpL {
    overflow: hidden;
}
.Headline_Headline__uU9f4 {
    height: calc(100vh);
}

.Headline_Fill__2TfeK {
    background-color: #1b2b4f;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.Headline_Content__g5TMx {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}

.Headline_Title__2wx8o {
    font-size: 5.5vw;
    font-weight: bold;
}

.Headline_Name__O4e_L {
    color: #FF5CFF;
}

.Headline_Subtitle__1Z9Ej {
    font-size: 4.5vw;
    font-family: 'Source Code Pro', monospace;
}

.Headline_Arrow__3HYKW {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%,-15%);
}

.Headline_Arrow__3HYKW span{
    display: block;
    width: calc(1vw + 1vh);
    height: calc(1vw + 1vh);
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    transform: rotate(45deg);
    margin: -10px;
    -webkit-animation: Headline_arrowAnimation__1u6FC 2s infinite;
            animation: Headline_arrowAnimation__1u6FC 2s infinite;
}

.Headline_Arrow__3HYKW span:nth-child(2){
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}

.Headline_Arrow__3HYKW span:nth-child(3){
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}

@-webkit-keyframes Headline_arrowAnimation__1u6FC {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}

@keyframes Headline_arrowAnimation__1u6FC {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}

@media (min-width: 768px) {
    .Headline_Title__2wx8o {
        font-size: 4vw;
    }

    .Headline_Subtitle__1Z9Ej {
        font-size: 3vw;
    }
}
.About_About__scHdH {
    background-color: #eee;
    padding-top: 100px;
    padding-bottom: 100px;
}

.Profile_Profile__1AGLR {
    /*text-align: center;*/
    margin: 0 20px;
}

.Profile_Profile__1AGLR img {
    width: 80%;
    border-radius: 50%;
    margin-bottom: 30px;
    box-shadow: -3px 3px 10px #777;
}

.Profile_Title__2ODRF {
    font-family: 'Source Code Pro', monospace;
    font-size: 2.2rem;
    margin-bottom: 10px;
}

.Profile_Letter__1rhdp {
    transition: 2s;
}

.Profile_Letter__1rhdp:hover {
    color: #dc389c;
    transition: 0.05s;
    cursor: default;
}
.Skills_Skills__kO-xO {
    margin: 0 20px;
}

.Skills_SkillSection__3HXPF {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 70px;
}

.Skills_SkillTitle__2xddP {
    font-size: 2rem;
    margin-bottom: 20px;
    font-family: 'Source Code Pro', monospace;
}

.Skills_Item__1747z {
    background-color: #1b2b4f;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    padding: 8px 30px 10px;
    display: inline-block;
    font-size: 1.2rem;
}

.Skills_Icon__-xe8U {
    font-size: 2em;
}

.Portfolio_Portfolio__3g7-2 {
    background-color:#fff;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.Portfolio_Projects__9tS5Z {
    padding-top: 20px;
    margin-top: 20px;
}

.Portfolio_NoScroll__2XJpn {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

/* Small hack to prevent flickering when selecting filter buttons and div height gets very small for a moment */
@media (min-width: 576px) {
    .Portfolio_Portfolio__3g7-2 {
        min-height: 1000px;
    }
}

@media (min-width: 768px) {
    .Portfolio_Portfolio__3g7-2 {
        min-height: 1000px;
    }
}

@media (min-width: 992px) {
    .Portfolio_Portfolio__3g7-2 {
        min-height: 755px;
    }
}

@media (min-width: 1200px) {
    .Portfolio_Portfolio__3g7-2 {
        min-height: 845px;
    }
}
.FilterButton_FilterButton__1gjtF {
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Source Code Pro', monospace;
    font-size: 1.5rem;
    padding: 10px;
    border-radius: 10px;
}

.FilterButton_FilterButton__1gjtF:hover {
    background-color: #FF5CFF;
    color: white;
    transition: 0.2s;
}

.FilterButton_Default__3YXd4 {
    color: black;
    transition: 0.2s;
}

.FilterButton_Selected__2m0tN {
    background-color: #FF5CFF;
    color: white;
    transition: 0.2s;
}
.ProjectCard_ProjectCard__gDqiR {
    margin-bottom: 31px;
}

.ProjectCard_Image__LACGw img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 1px 10px #999;
}

.ProjectCard_Info__192lk {
    background-color: rgba(27, 43, 79, 0.95);
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    height: calc(100% - 31px);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.ProjectCard_Info__192lk:hover {
    opacity: 1;
    cursor: pointer;
}

.ProjectCard_Description__Kbg-I {
    color: white;
    margin: auto;
}

.ProjectCard_Title__2d_br {
    font-weight: 700;
    font-size: 1.8rem;
}

.ProjectCard_Subtitle__3UQ2c {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
    font-size: 1rem;
}

.ProjectCard_Icon__1hnyw {
    color: white;
    cursor: pointer;
    font-size: 2.3rem;
    padding-top: 10px;
    transition: 0.1s ease-in-out;
}

.ProjectCard_Icon__1hnyw:hover {
    color: #FF5CFF;
    transition: 0.1s ease-in-out;
}

@media (min-width: 768px) {
    .ProjectCard_Title__2d_br {
        font-size: 3em;
    }
    .ProjectCard_Subtitle__3UQ2c {
        font-size: 1.5rem;
    }
    .ProjectCard_Icon__1hnyw {
        font-size: 3rem;
        padding-top: 15px;
    }
}

@media (min-width: 992px) {
    .ProjectCard_Title__2d_br {
        font-size: 1.2rem;
    }
    .ProjectCard_Subtitle__3UQ2c {
        font-size: 0.7rem;
    }
    .ProjectCard_Icon__1hnyw {
        font-size: 1.5rem;
        padding-top: 5px;
    }
}

@media (min-width: 1200px) {
    .ProjectCard_Title__2d_br {
        font-size: 1.8rem;
    }
    .ProjectCard_Subtitle__3UQ2c {
        font-size: 1rem;
    }
    .ProjectCard_Icon__1hnyw {
        font-size: 2.3rem;
        padding-top: 10px;
    }
}
.Footer_Footer__238hM {
    background-color: #1b2b4f;
    color: white;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.Footer_Links__mnUs6 {
    padding-bottom: 15px;
}

.Footer_IconLink__1hQWF {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    color: white;
}

.Footer_Icon__2abUb {
    font-size: 2em;
    opacity: 0.7;
}

.Footer_Icon__2abUb:hover {
    color: white;
    opacity: 1;
}

.Carousel_Carousel__1Zeyw {
    width: 100%;
    margin-bottom: 40px;
}

.Carousel_View__16Jm5 img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.Carousel_VideoContainer__1Xk1Z {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 20px;
}

.Carousel_Video__2QQpW {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.Carousel_Caption__2l-zM {
    font-size: 0.9rem;
    margin-bottom: 8px;
}

.Carousel_Pagination__2nqq- img {
    width: 25%;
    border-radius: 8px;
    padding: 5px;
    opacity: 0.5;
}

.Carousel_Pagination__2nqq- img:hover {
    cursor: pointer;
}

#Carousel_SelectedSlide__2u5Zc {
    opacity: 1;
}

@media (min-width: 768px) {
    .Carousel_Pagination__2nqq- img {
        border-radius: 15px;
        padding: 10px;
    }
}
.Modal_Backdrop__3jTYR {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
}

.Modal_Content__cJzdw {
    color: #dcdcdc;
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 100px;
    padding-bottom: 100px;
    overflow-y: auto;
}

.Modal_CloseButton__1OvG0 {
    color: #ddd;
    font-size: 1.25em;
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px;
}

.Modal_CloseButton__1OvG0:hover {
    cursor: pointer;
}

.Modal_Info__rc3qS {
    text-align: left;
}

.Modal_Title__LGlmO {
    font-weight: 700;
    font-size: 2em;
    line-height: 1.2em;
}

.Modal_Time__36f9y {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.9em;
    letter-spacing: 2px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.Modal_Tags__2v1y_ {
    margin-bottom: 30px;
}

.Modal_Tag__1nio7 {
    background-color: #2a3e6d;
    color: white;
    display: inline-block;
    margin-right: 10px;
    padding: 4px 10px 4px;
    border-radius: 50px;
    font-size: 0.9em;
    font-weight: 300px;
}

.Modal_Description__39zw- {
    margin-bottom: 40px;
}

.Modal_LinkButton__2JtZ- {
    background-color: lightgray;
    color: black;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.Modal_LinkButton__2JtZ-:hover {
    background-color: white;
    color: black;
    text-decoration: none;
}

@media (max-width: 1199.98px) {
    .Modal_Info__rc3qS {
        text-align: center;
    }
    .Modal_Description__39zw- {
        text-align: left;
    }
}

