.Footer {
    background-color: #1b2b4f;
    color: white;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.Links {
    padding-bottom: 15px;
}

.IconLink {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    color: white;
}

.Icon {
    font-size: 2em;
    opacity: 0.7;
}

.Icon:hover {
    color: white;
    opacity: 1;
}
